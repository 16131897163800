import { render, staticRenderFns } from "./LearnAbout.vue?vue&type=template&id=1ec7ad55&scoped=true&"
import script from "./LearnAbout.js?vue&type=script&lang=js&"
export * from "./LearnAbout.js?vue&type=script&lang=js&"
import style0 from "./LearnAbout.vue?vue&type=style&index=0&id=1ec7ad55&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ec7ad55",
  null
  
)

export default component.exports